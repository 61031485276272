import React, { useState, useEffect } from 'react';
import { useTheme, Table, TableBody, TableCell, TableContainer, TableRow, Collapse, Box, IconButton, Typography, TableHead, Paper, Button } from '@mui/material';

import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { tokens } from "../../data/tokens";
import { UseAppConfig } from '../../system/config';
import { sendPostData, getDataArray } from '../../system/getData';
import ConfirmationDialog from "../globals/confirmation";

const ArticleItem = ({ articulo, nivel, onExpand }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
        onExpand(articulo.id, isExpanded);
    };

    return (
        <>
            <TableRow>
                <TableCell align="center" style={{ paddingLeft: `${nivel * 20}px` }}>{articulo.clave}</TableCell>
                <TableCell align="center">{articulo.tipo}</TableCell>
                <TableCell align="center">{articulo.descCorta}</TableCell>
                <TableCell align="center">{articulo.familia}</TableCell>
                <TableCell align="center">{articulo.unidad}</TableCell>
                <TableCell align="center">{formatCurrency(articulo.precioVenta)}</TableCell>
            </TableRow>
            {articulo.children?.length > 0 && (
                <TableRow>
                    <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table>
                                    <TableBody>
                                        {articulo.children.map((childArticulo) => (
                                            <ArticleItem key={childArticulo.id} articulo={childArticulo} nivel={nivel + 1} onExpand={onExpand} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export const ArticlesTable = ({ data, nivel = 0 }) => {
    const { config } = UseAppConfig();
    const [articulos, setArticulos] = useState([]);
    const [loading, setLoading] = useState(true);
    const { enqueueSnackbar } = useSnackbar();

    const [selectedCatID, setCatID] = useState('');
    const [open, setStatusOpen] = useState(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
    };

    const fetchData = async () => {
        try {
            setLoading(true);
            const apiData = await getDataArray(`${config.API_BASE_URL}/${data.endpoint}`, { params: data.endpointParams });
            setArticulos(apiData);
            console.log('Datos obtenidos desde la API:', apiData);
        } catch (error) {
            console.error('Error al obtener los datos:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [data.endpoint, data.endpointParams, config.API_BASE_URL]);

    if (loading) {
        return <div style={{ textAlign: 'center' }}>Cargando artículos...</div>;
    }

    if (articulos.length === 0) {
        return (
            <div
                style={{
                    textAlign: 'center',
                    backgroundColor: '#cccccc',
                    color: '#721c24',
                    padding: '20px',
                    marginTop: '20px',
                    marginLeft: '20px',
                    marginRight: '15px',
                    height: '5vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px'
                }}
            >
                No se ha agregado ningún artículo.
            </div>
        );
    }

    const handleStatusOpen = (id) => {
        setCatID(id);
        setStatusOpen(true);
    };

    const handleStatusClose = () => {
        setStatusOpen(false);
    };

    const handleConfirm = async () => {
        try {
            const url = `${config.API_BASE_URL}/retira/articuloCategoria`;
            const params = { id: selectedCatID };
            const result = await sendPostData(url, params);
            enqueueSnackbar('El Artículo se ha retirado exitosamente', { variant: 'success' });
            setStatusOpen(false);
            fetchData();
        } catch (error) {
            console.error('Error al intentar actualizar el Estado', error);
            enqueueSnackbar('Error al intentar retirar este Artículo', { variant: 'error' });
            setStatusOpen(false);
        }
        console.log('Confirmado');
    };

    return (
        <Box
            maxWidth="100%"
            mx="auto"
            mt="-10px"
            sx={{
                "& .name-column--cell": { color: colors.greenAccent[300] },
                "& .MuiTableHead-root": {
                    backgroundColor: colors.primary[500],
                    borderBottom: "none",
                    textAlign: "center",
                },
                "& .MuiTableCell-head": { color: colors.grey[900], padding: '4px 8px' },
                "& .MuiTableContainer-root": {
                    backgroundColor: colors.primary[400],
                    padding: '4px 8px',
                    lineHeight: '1',
                },
                "& .MuiTableFooter-root": {
                    borderTop: "none",
                    backgroundColor: colors.grey[900],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                },
            }}
        >
            <TableContainer
                component={Paper}
                sx={{
                    marginLeft: `${nivel * 20}px`,
                    marginTop: '20px',
                    marginLeft: '25px',
                    maxWidth: 'calc(100% - 40px)'
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976d2' }}>
                            <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Clave</TableCell>
                            <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Tipo</TableCell>
                            <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Descripción</TableCell>
                            <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Familia</TableCell>
                            <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Unidad</TableCell>
                            <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Precio de Venta</TableCell>
                            <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold' }}>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {articulos.map((articulo) => (
                            <TableRow key={articulo.id}>
                                <TableCell align="center">{articulo.clave}</TableCell>
                                <TableCell align="center">{articulo.tipo}</TableCell>
                                <TableCell align="center">{articulo.descripcion}</TableCell>
                                <TableCell align="center">{articulo.familia}</TableCell>
                                <TableCell align="center">{articulo.unidad}</TableCell>
                                <TableCell align="center">{formatCurrency(articulo.precioVenta)}</TableCell>
                                <TableCell align="center">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        style={{ minWidth: '25px', marginRight: '5px' }}
                                        onClick={() => handleStatusOpen(articulo.rcdID)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialog
                open={open}
                onClose={handleStatusClose}
                onConfirm={handleConfirm}
                statusValue={2}
            />
        </Box >
    );
};
